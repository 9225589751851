"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMessageForwarderUrlByEnv = exports.getWorkshopUrlByEnv = exports.getLaunchDarklyEnv = exports.getsiteCacheImageUrlByEnv = exports.getSiteCacheApiUrlByEnv = exports.getTranslationUrlByEnv = exports.getWcsUrlByEnv = exports.getStreamingUrlByEnv = exports.getOperationResolverUrlByEnv = exports.getGuideDiscoveryUrlByEnv = exports.getOperationProvisionerUrlByEnv = exports.getGuideMessageGatewayByEnv = exports.getSignalRHubUrlByEnv = exports.getClientIdByEnv = exports.getAuthorityByEnv = exports.getGuideRequestServiceByEnv = exports.getRegistryByEnv = exports.getGuideViewsBucketUrlByEnv = exports.getGuideStorageByEnv = exports.getLoggingServiceByEnv = exports.getEnvironment = void 0;
const getEnvironment = () => {
    const domain = window.location.host.toString().toLowerCase();
    let environmentTag = 'local';
    if (domain.indexOf('sws-dev.devtest') !== -1) {
        environmentTag = 'dev';
    }
    else if (domain.indexOf('sws-preprod.prod') !== -1) {
        environmentTag = 'pre';
    }
    else if (domain.indexOf('preprod-sws.scania.com') !== -1) {
        environmentTag = 'pre';
    }
    else if (domain.indexOf('verification-sws.scania.com') !== -1) {
        environmentTag = 'ver';
    }
    else if (domain.indexOf('sws-verification.prod') !== -1) {
        environmentTag = 'ver';
    }
    else if (domain.indexOf('sws-ft.prod') !== -1) {
        environmentTag = 'ft';
    }
    else if (domain.indexOf('ft-sws.scania.com') !== -1) {
        environmentTag = 'ft';
    }
    else if (domain.indexOf('sws-fieldtest.prod') !== -1) {
        environmentTag = 'prod';
    }
    else if (domain.indexOf('sws-prod.prod') !== -1) {
        environmentTag = 'prod';
    }
    else if (domain.indexOf('sws.scania.com') !== -1) {
        environmentTag = 'prod';
    }
    return environmentTag;
};
exports.getEnvironment = getEnvironment;
const configuration = {
    local: {
        loggingApiService: 'https://logging-lambda.sws-dev.devtest.aws.scania.com/api/v1/LogEventBatch',
        guideStorage: 'https://guide-storage.sws-dev.devtest.aws.scania.com/api/flow?',
        guideViewsBucketUrl: 'https://guide-views.sws-dev.devtest.aws.scania.com/',
        registryUrl: 'https://guide-registry.sws-dev.devtest.aws.scania.com/api/',
        guideRequestService: 'https://guide-launchpad.sws-dev.devtest.aws.scania.com/',
        guideMessageGateway: 'https://guide-message-gateway.sws-dev.devtest.aws.scania.com/api/SwsMessage',
        ecuUpdateGuidesDiscoveryApi: 'https://guide-discovery.sws-dev.devtest.aws.scania.com/api/',
        identity: 'https://fg.ciam.preprod.aws.scania.com/auth/realms/scania/',
        clientId: 'sws-guide-host-app-azuread',
        secureNotificationBackendApi: 'https://secure-notification.sws-dev.devtest.aws.scania.com/',
        operationProvisioner: 'https://sws-oas-provisioner.sws-dev.devtest.aws.scania.com/api',
        operationResolver: 'https://operation-resolver.sws-dev.devtest.aws.scania.com/api',
        translationUrl: 'https://gui-strings.sws-dev.devtest.aws.scania.com/',
        streamingUrl: 'https://guide-streaming-service.sws-dev.devtest.aws.scania.com/api',
        wcsUrl: 'https://wcs.sws-dev.devtest.aws.scania.com',
        siteCacheApiUrl: 'https://sitecacheapi.tis.prod.aws.scania.com/',
        siteCacheUrl: 'https://sitecache.tis.prod.aws.scania.com/int/',
        launchDarklyEnv: '5a785ae25b54961717c60f07',
        workshopUrl: `https://admin-bff.sws-dev.devtest.aws.scania.com/api/sws-workshop-user/WorkshopUser/GetUserOverViewWorkshopList`,
        messageForwarderUrl: 'https://guide-message-forwarder.sws-dev.devtest.aws.scania.com/'
    },
    dev: {
        loggingApiService: 'https://logging-lambda.sws-dev.devtest.aws.scania.com/api/v1/LogEventBatch',
        guideStorage: 'https://guide-storage.sws-dev.devtest.aws.scania.com/api/flow?',
        guideViewsBucketUrl: 'https://guide-views.sws-dev.devtest.aws.scania.com/',
        registryUrl: 'https://guide-registry.sws-dev.devtest.aws.scania.com/api/',
        guideRequestService: 'https://guide-launchpad.sws-dev.devtest.aws.scania.com/',
        guideMessageGateway: 'https://guide-message-gateway.sws-dev.devtest.aws.scania.com/api/SwsMessage',
        ecuUpdateGuidesDiscoveryApi: 'https://guide-discovery.sws-dev.devtest.aws.scania.com/api/',
        identity: 'https://fg.ciam.preprod.aws.scania.com/auth/realms/scania/',
        clientId: 'sws-guide-host-app-azuread',
        secureNotificationBackendApi: 'https://secure-notification.sws-dev.devtest.aws.scania.com/',
        operationProvisioner: 'https://sws-oas-provisioner.sws-dev.devtest.aws.scania.com/api',
        operationResolver: 'https://operation-resolver.sws-dev.devtest.aws.scania.com/api',
        translationUrl: 'https://gui-strings.sws-dev.devtest.aws.scania.com/',
        streamingUrl: 'https://guide-streaming-service.sws-dev.devtest.aws.scania.com/api',
        wcsUrl: 'https://wcs.sws-dev.devtest.aws.scania.com',
        siteCacheApiUrl: 'https://sitecacheapi.tis.prod.aws.scania.com/',
        siteCacheUrl: 'https://sitecache.tis.prod.aws.scania.com/int/',
        launchDarklyEnv: '5a785ae25b54961717c60f07',
        workshopUrl: `https://admin-bff.sws-dev.devtest.aws.scania.com/api/sws-workshop-user/WorkshopUser/GetUserOverViewWorkshopList`,
        messageForwarderUrl: 'https://guide-message-forwarder.sws-dev.devtest.aws.scania.com/'
    },
    pre: {
        loggingApiService: 'https://logging-lambda.sws-preprod.prod.aws.scania.com/api/v1/LogEventBatch',
        guideStorage: 'https://guide-storage.sws-preprod.prod.aws.scania.com/api/flow?',
        guideViewsBucketUrl: 'https://guide-views.sws-preprod.prod.aws.scania.com/',
        registryUrl: 'https://guide-registry.sws-preprod.prod.aws.scania.com/api/',
        guideRequestService: 'https://guide-launchpad.sws-preprod.prod.aws.scania.com/',
        guideMessageGateway: 'https://guide-message-gateway.sws-preprod.prod.aws.scania.com/api/SwsMessage',
        ecuUpdateGuidesDiscoveryApi: 'https://guide-discovery.sws-preprod.prod.aws.scania.com/api/',
        identity: 'https://fg.ciam.preprod.aws.scania.com/auth/realms/scania/',
        clientId: 'sws-guide-host-app-azuread',
        secureNotificationBackendApi: 'https://secure-notification.sws-preprod.prod.aws.scania.com/',
        operationProvisioner: 'https://sws-oas-provisioner.sws-preprod.prod.aws.scania.com/api',
        operationResolver: 'https://operation-resolver.sws-preprod.prod.aws.scania.com/api',
        translationUrl: 'https://gui-strings.sws-preprod.prod.aws.scania.com/',
        streamingUrl: 'https://guide-streaming-service.sws-preprod.prod.aws.scania.com/api',
        wcsUrl: 'https://wcs.sws-preprod.prod.aws.scania.com',
        siteCacheApiUrl: 'https://sitecacheapi.tis.prod.aws.scania.com/',
        siteCacheUrl: 'https://sitecache.tis.prod.aws.scania.com/int/',
        launchDarklyEnv: '5d25c43a2af79507673ae024',
        workshopUrl: `https://admin-bff.sws-preprod.prod.aws.scania.com/api/sws-workshop-user/WorkshopUser/GetUserOverViewWorkshopList`,
        messageForwarderUrl: 'https://guide-message-forwarder.sws-preprod.prod.aws.scania.com/'
    },
    ver: {
        loggingApiService: 'https://logging-lambda.sws-verification.prod.aws.scania.com/api/v1/LogEventBatch',
        guideStorage: 'https://guide-storage.sws-verification.prod.aws.scania.com/api/flow?',
        guideViewsBucketUrl: 'https://guide-views.sws-verification.prod.aws.scania.com/',
        registryUrl: 'https://guide-registry.sws-verification.prod.aws.scania.com/api/',
        guideRequestService: 'https://guide-launchpad.sws-verification.prod.aws.scania.com/',
        guideMessageGateway: 'https://guide-message-gateway.sws-verification.prod.aws.scania.com/api/SwsMessage',
        ecuUpdateGuidesDiscoveryApi: 'https://guide-discovery.sws-verification.prod.scania.com/api/',
        identity: 'https://fg.ciam.preprod.aws.scania.com/auth/realms/scania/',
        clientId: 'sws-guide-host-app-azuread',
        secureNotificationBackendApi: 'https://secure-notification.sws-verification.prod.aws.scania.com/',
        operationProvisioner: 'https://sws-oas-provisioner.sws-verification.prod.aws.scania.com/api',
        operationResolver: 'https://operation-resolver.sws-verification.prod.aws.scania.com/api',
        translationUrl: 'https://gui-strings.sws-verification.prod.aws.scania.com/',
        streamingUrl: 'https://guide-streaming-service.sws-verification.prod.aws.scania.com/api',
        wcsUrl: 'https://wcs.sws-verification.prod.aws.scania.com',
        siteCacheApiUrl: 'https://sitecacheapi.tis.prod.aws.scania.com/',
        siteCacheUrl: 'https://sitecache.tis.prod.aws.scania.com/int/',
        launchDarklyEnv: '5cdab1980a85d90824229737',
        workshopUrl: `https://admin-bff.sws-verification.prod.aws.scania.com/api/sws-workshop-user/WorkshopUser/GetUserOverViewWorkshopList`,
        messageForwarderUrl: 'https://guide-message-forwarder.sws-verification.prod.aws.scania.com/'
    },
    ft: {
        loggingApiService: 'https://logging-lambda.sws-ft.prod.aws.scania.com/api/v1/LogEventBatch',
        guideStorage: 'https://guide-storage.sws-ft.prod.aws.scania.com/api/flow?',
        guideViewsBucketUrl: 'https://guide-views.sws-ft.prod.aws.scania.com/',
        registryUrl: 'https://guide-registry.sws-ft.prod.aws.scania.com/api/',
        guideRequestService: 'https://guide-launchpad.sws-ft.prod.aws.scania.com/',
        guideMessageGateway: 'https://guide-message-gateway.sws-ft.prod.aws.scania.com/api/SwsMessage',
        ecuUpdateGuidesDiscoveryApi: 'https://guide-discovery.sws-ft.prod.scania.com/api/',
        identity: 'https://fg.ciam.preprod.aws.scania.com/auth/realms/scania/',
        clientId: 'sws-guide-host-app-azuread',
        secureNotificationBackendApi: 'https://secure-notification.sws-ft.prod.aws.scania.com/',
        operationProvisioner: 'https://sws-oas-provisioner.sws-ft.prod.aws.scania.com/api',
        operationResolver: 'https://operation-resolver.sws-ft.prod.aws.scania.com/api',
        translationUrl: 'https://gui-strings.sws-ft.prod.aws.scania.com/',
        streamingUrl: 'https://guide-streaming-service.sws-ft.prod.aws.scania.com/api',
        wcsUrl: 'https://wcs.sws-ft.prod.aws.scania.com',
        siteCacheApiUrl: 'https://sitecacheapi.tis.prod.aws.scania.com/',
        siteCacheUrl: 'https://sitecache.tis.prod.aws.scania.com/int/',
        launchDarklyEnv: '5ceb9f47a3ccc20756676ecb',
        workshopUrl: `https://admin-bff.sws-ft.prod.aws.scania.com/api/sws-workshop-user/WorkshopUser/GetUserOverViewWorkshopList`,
        messageForwarderUrl: 'https://guide-message-forwarder.sws-ft.prod.aws.scania.com/'
    },
    prod: {
        loggingApiService: 'https://logging-lambda.sws-prod.prod.aws.scania.com/api/v1/LogEventBatch',
        guideStorage: 'https://guide-storage.sws-prod.prod.aws.scania.com/api/flow?',
        guideViewsBucketUrl: 'https://guide-views.sws-prod.prod.aws.scania.com/',
        registryUrl: 'https://guide-registry.sws-prod.prod.aws.scania.com/api/',
        guideRequestService: 'https://guide-launchpad.sws-prod.prod.aws.scania.com/',
        guideMessageGateway: 'https://guide-message-gateway.sws-prod.prod.aws.scania.com/api/SwsMessage',
        ecuUpdateGuidesDiscoveryApi: 'https://guide-discovery.sws-prod.prod.scania.com/api/',
        identity: 'https://fg.ciam.prod.aws.scania.com/auth/realms/scania/',
        clientId: 'sws-guide-host-app-azuread',
        secureNotificationBackendApi: 'https://secure-notification.sws-prod.prod.aws.scania.com/',
        operationProvisioner: 'https://sws-oas-provisioner.sws-prod.prod.aws.scania.com/api',
        operationResolver: 'https://operation-resolver.sws-prod.prod.aws.scania.com/api',
        translationUrl: 'https://gui-strings.sws-prod.prod.aws.scania.com/',
        streamingUrl: 'https://guide-streaming-service.sws-prod.prod.aws.scania.com/api',
        wcsUrl: 'https://wcs.sws-prod.prod.aws.scania.com',
        siteCacheApiUrl: 'https://sitecacheapi.tis.prod.aws.scania.com/',
        siteCacheUrl: 'https://sitecache.tis.prod.aws.scania.com/int/',
        launchDarklyEnv: '5aa786c2b9b7db2ab81f9358',
        workshopUrl: `https://admin-bff.sws-prod.prod.aws.scania.com/api/sws-workshop-user/WorkshopUser/GetUserOverViewWorkshopList`,
        messageForwarderUrl: 'https://guide-message-forwarder.sws-prod.prod.aws.scania.com/'
    },
};
const getLoggingServiceByEnv = () => configuration[(0, exports.getEnvironment)()].loggingApiService;
exports.getLoggingServiceByEnv = getLoggingServiceByEnv;
const getGuideStorageByEnv = () => configuration[(0, exports.getEnvironment)()].guideStorage;
exports.getGuideStorageByEnv = getGuideStorageByEnv;
const getGuideViewsBucketUrlByEnv = () => configuration[(0, exports.getEnvironment)()].guideViewsBucketUrl;
exports.getGuideViewsBucketUrlByEnv = getGuideViewsBucketUrlByEnv;
const getRegistryByEnv = () => configuration[(0, exports.getEnvironment)()].registryUrl;
exports.getRegistryByEnv = getRegistryByEnv;
const getGuideRequestServiceByEnv = () => configuration[(0, exports.getEnvironment)()].guideRequestService;
exports.getGuideRequestServiceByEnv = getGuideRequestServiceByEnv;
const getAuthorityByEnv = () => configuration[(0, exports.getEnvironment)()].identity;
exports.getAuthorityByEnv = getAuthorityByEnv;
const getClientIdByEnv = () => configuration[(0, exports.getEnvironment)()].clientId;
exports.getClientIdByEnv = getClientIdByEnv;
const getSignalRHubUrlByEnv = () => configuration[(0, exports.getEnvironment)()].secureNotificationBackendApi;
exports.getSignalRHubUrlByEnv = getSignalRHubUrlByEnv;
const getGuideMessageGatewayByEnv = () => configuration[(0, exports.getEnvironment)()].guideMessageGateway;
exports.getGuideMessageGatewayByEnv = getGuideMessageGatewayByEnv;
const getOperationProvisionerUrlByEnv = () => configuration[(0, exports.getEnvironment)()].operationProvisioner;
exports.getOperationProvisionerUrlByEnv = getOperationProvisionerUrlByEnv;
const getGuideDiscoveryUrlByEnv = () => configuration[(0, exports.getEnvironment)()].ecuUpdateGuidesDiscoveryApi;
exports.getGuideDiscoveryUrlByEnv = getGuideDiscoveryUrlByEnv;
const getOperationResolverUrlByEnv = () => configuration[(0, exports.getEnvironment)()].operationResolver;
exports.getOperationResolverUrlByEnv = getOperationResolverUrlByEnv;
const getStreamingUrlByEnv = () => configuration[(0, exports.getEnvironment)()].streamingUrl;
exports.getStreamingUrlByEnv = getStreamingUrlByEnv;
const getWcsUrlByEnv = () => configuration[(0, exports.getEnvironment)()].wcsUrl;
exports.getWcsUrlByEnv = getWcsUrlByEnv;
const getTranslationUrlByEnv = () => configuration[(0, exports.getEnvironment)()].translationUrl;
exports.getTranslationUrlByEnv = getTranslationUrlByEnv;
const getSiteCacheApiUrlByEnv = () => configuration[(0, exports.getEnvironment)()].siteCacheApiUrl;
exports.getSiteCacheApiUrlByEnv = getSiteCacheApiUrlByEnv;
const getsiteCacheImageUrlByEnv = () => configuration[(0, exports.getEnvironment)()].siteCacheUrl;
exports.getsiteCacheImageUrlByEnv = getsiteCacheImageUrlByEnv;
const getLaunchDarklyEnv = () => configuration[(0, exports.getEnvironment)()].launchDarklyEnv;
exports.getLaunchDarklyEnv = getLaunchDarklyEnv;
const getWorkshopUrlByEnv = () => configuration[(0, exports.getEnvironment)()].workshopUrl;
exports.getWorkshopUrlByEnv = getWorkshopUrlByEnv;
const getMessageForwarderUrlByEnv = () => configuration[(0, exports.getEnvironment)()].messageForwarderUrl;
exports.getMessageForwarderUrlByEnv = getMessageForwarderUrlByEnv;
