"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGuideRegistry = void 0;
const chunk_host_1 = require("@guided-methods/chunk-host");
const messaging_common_1 = require("@guided-methods/messaging-common");
const testGuides = {
    'local-guide-v0.0': {
        guideId: 'local-guide-v0.0',
        chunks: [
            {
                type: messaging_common_1.ChunkType.View,
                chunkId: 'views',
                main: false,
                url: 'https://localhost:9000/index.html',
                canRunOn: [chunk_host_1.RunnerType.Browser],
            },
            {
                type: messaging_common_1.ChunkType.Flow,
                chunkId: 'flow',
                main: true,
                url: 'https://localhost:9001/index.js',
                canRunOn: [chunk_host_1.RunnerType.Browser, chunk_host_1.RunnerType.Server],
            },
        ],
    },
};
const guidesWithoutView = {
    'local-flow-v0.0': {
        guideId: 'local-flow-v0.0',
        chunks: [
            {
                type: messaging_common_1.ChunkType.Flow,
                chunkId: 'flow',
                main: true,
                url: 'https://localhost:9001/index.js',
                canRunOn: [chunk_host_1.RunnerType.Browser, chunk_host_1.RunnerType.Server],
            },
        ],
    },
};
const mapResponse = (input) => ({
    guideId: input.guideId,
    chunks: input.chunks.map(({ chunkId, chunkType, chunkUrl }) => ({
        canRunOn: chunkType === 'flow' ? [chunk_host_1.RunnerType.Browser, chunk_host_1.RunnerType.Server] : [chunk_host_1.RunnerType.Browser],
        chunkId: chunkId,
        main: chunkId === 'flow',
        type: chunkType === 'flow' ? messaging_common_1.ChunkType.Flow : messaging_common_1.ChunkType.View,
        url: chunkUrl,
    })),
});
const createGuideRegistry = (http, registryUrl) => async (guideId) => {
    try {
        if (guideId === 'local-guide-v0.0') {
            const guide = testGuides[guideId];
            return guide;
        }
        if (guideId === 'local-flow-v0.0') {
            return guidesWithoutView[guideId];
        }
        const response = await http.get(`${registryUrl}${encodeURIComponent(guideId)}`);
        const { status, data } = response;
        if (status === 200) {
            return mapResponse(data);
        }
        else {
            throw new Error(`registry responded with ${status}`);
        }
    }
    catch (e) {
        throw new Error(`Failed to fetch guide descriptor for ${guideId}, error was: ${e instanceof Error ? e.message : "Unknown error"}`);
    }
};
exports.createGuideRegistry = createGuideRegistry;
